import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styledComponent from 'styled-components/macro';
import styled from 'styled-components/macro';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import drop from '../../assets/SVG/dropdown.svg';
import { useState } from 'react';
import ModalComponent from '../ModalComponent';

const StyledHeadCell = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  border: 1px solid white;
  color: black;
  background: #4f4e24;
`;

const StyledTableRow = styled(TableRow)`
  border-bottom: 3px solid white;
  border-top: 3px solid white;
  th:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  th:last-child {
    border-radius: 0px 35px 35px 0px;
  }
  td:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  td:last-child {
    border-radius: 0px 35px 35px 0px;
  }
`;

const StyledTableCell = styled(TableCell)`
  align-items: center;

  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  border: 1px solid white;
  display: table-cell;
  overflow: auto;
  background: ${props => {
    const color = Number(props.color);
    return color === 2 // Paid
      ? '#B2BA86'
      : color === 1 // Pending
      ? '#dfdccb'
      : color === 0 // Late
      ? '#D98458'
      : '#f8f8f8';
  }};
  color: ${props => {
    const color = Number(props.color);
    return color === 2
      ? 'white !important'
      : color === 1
      ? 'black !important'
      : color === 0
      ? 'white !important'
      : 'black';
  }};
`;

const StyledTableCellMobile = styled(TableCell)`
  align-items: center;

  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  // border: 1px solid white;
  width: calc(20% - 1px);
  box-sizing: border-box;
`;

const StyledHeadCellMobile = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  // border: 1px solid white;
  background: #ebeaea;
  background: ${props => props.color};
  width: calc(20% - 1px);
  box-sizing: border-box;
`;

const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  background: #ebeaea;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const ContainerBody = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #f8f8f8;
`;
const CleanFilter = () => (
  <svg
    width="25px"
    height="25px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 15L21 21M21 15L15 21M10 21V14.6627C10 14.4182 10 14.2959 9.97237 14.1808C9.94787 14.0787 9.90747 13.9812 9.85264 13.8917C9.7908 13.7908 9.70432 13.7043 9.53137 13.5314L3.46863 7.46863C3.29568 7.29568 3.2092 7.2092 3.14736 7.10828C3.09253 7.01881 3.05213 6.92127 3.02763 6.81923C3 6.70414 3 6.58185 3 6.33726V4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H19.4C19.9601 3 20.2401 3 20.454 3.10899C20.6422 3.20487 20.7951 3.35785 20.891 3.54601C21 3.75992 21 4.03995 21 4.6V6.33726C21 6.58185 21 6.70414 20.9724 6.81923C20.9479 6.92127 20.9075 7.01881 20.8526 7.10828C20.7908 7.2092 20.7043 7.29568 20.5314 7.46863L17 11"
      stroke="#000000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface TableProps {
  historyInvoices?: any[];
  page?: boolean;
  setHistoryInvoices?: (value: any) => void;
  setActualPageInvoices: (value: any) => void;
  setPagesInvoices: (value: any) => void;
  pages?: any;
  actualPageInvoices?: any;
  setOrigin: (value: any) => void;
  handleChangeInvoices: (event: any, value: any) => void;
}

export function HistoryITable({
  historyInvoices,
  page,
  setHistoryInvoices,
  setActualPageInvoices,
  setPagesInvoices,
  pages,
  actualPageInvoices,
  setOrigin,
  handleChangeInvoices,
}: TableProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [info, setInfo] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedData, setSortedData] = useState(
    (historyInvoices &&
      historyInvoices.sort((a, b) => {
        const statusOrder = {
          Late: 0,
          Pending: 1,
          Paid: 2,
        };

        const statusComparison = statusOrder[a.State] - statusOrder[b.State];
        if (statusComparison !== 0) {
          return statusComparison;
        }

        return a.date.localeCompare(b.date);
      })) ||
      [],
  );
  const [activeSortColumn, setActiveSortColumn] = useState<any>(null);
  const [isColumnClicked, setIsColumnClicked] = useState('');

  // const sortData = columnName => {
  //   const sortOrderModifier = sortOrder === 'asc' ? 1 : -1;

  //   const sorted = [...(sortedData || [])].sort((a, b) => {
  //     // Assuming your date format is sortable as a string
  //     return sortOrderModifier * a[columnName].localeCompare(b[columnName]);
  //   });

  //   setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  //   setSortedData(sorted);
  // };

  const sortData = columnName => {
    const sortOrderModifier = sortOrder === 'asc' ? 1 : -1;

    const sorted = [...(historyInvoices || [])].sort((a, b) => {
      return sortOrderModifier * a[columnName].localeCompare(b[columnName]);
    });

    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setSortedData(sorted);
    setActiveSortColumn(columnName); // Set the active sorting column
    setIsColumnClicked(columnName);
  };

  const isMobile = useMediaQuery('(max-width:767px)');

  const openModalWithInfo = info => {
    setModalOpen(true);
    setInfo(info);
  };

  const opciones = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return historyInvoices ? (
    <ThemeProvider theme={theme}>
      <div className="d-flex justify-content-center mt-5 mb-5">
        <Stack spacing={2}>
          <Pagination
            count={pages}
            page={actualPageInvoices}
            onChange={handleChangeInvoices}
          />
        </Stack>
      </div>
      {!isMobile ? (
        <>
          <TableContainer>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <StyledTableRow>
                  {/* <StyledHeadCell
                    style={{ color: 'white', cursor: 'pointer' }}
                    align="center"
                    onClick={() => sortData('date')}
                    onMouseEnter={() => setActiveSortColumn('date')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.table.date)}{' '}
                    {(activeSortColumn === 'date' || isColumnClicked) && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell> */}
                  <StyledHeadCell
                    style={{ color: 'white', cursor: 'pointer' }}
                    align="center"
                    onClick={() => sortData('date')}
                    onMouseEnter={() => setActiveSortColumn('date')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.table.date)}{' '}
                    {(activeSortColumn === 'date' ||
                      isColumnClicked === 'date') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>
                  {/* <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.form.ponumber)}
                  </StyledHeadCell> */}
                  <StyledHeadCell
                    style={{ color: 'white', cursor: 'pointer' }}
                    align="center"
                    onClick={() => sortData('po_number')}
                    onMouseEnter={() => setActiveSortColumn('po_number')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.form.ponumber)}{' '}
                    {(activeSortColumn === 'po_number' ||
                      isColumnClicked === 'po_number') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>
                  <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.table.number)}
                  </StyledHeadCell>
                  {/* <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.table.t_invoice)}
                  </StyledHeadCell> */}
                  <StyledHeadCell
                    style={{ color: 'white', cursor: 'pointer' }}
                    align="center"
                    onClick={() => sortData('total_invoice')}
                    onMouseEnter={() => setActiveSortColumn('total_invoice')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.table.t_invoice)}{' '}
                    {(activeSortColumn === 'total_invoice' ||
                      isColumnClicked === 'total_invoice') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>
                  <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.table.due_date)}
                  </StyledHeadCell>
                  <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.table.doc_type)}
                  </StyledHeadCell>
                  {/* <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.table.status)}
                  </StyledHeadCell> */}
                  <StyledHeadCell
                    style={{ color: 'white', cursor: 'pointer' }}
                    align="center"
                    onClick={() => sortData('status')}
                    onMouseEnter={() => setActiveSortColumn('status')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.table.status)}{' '}
                    {(activeSortColumn === 'status' ||
                      isColumnClicked === 'status') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {sortedData &&
                  sortedData.map(groupBy => (
                    <StyledTableRow key={Math.random()}>
                      <StyledTableCell align="center">
                        {groupBy.DocumentDate}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {groupBy.PoNumber}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {groupBy.DocumentNumber}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {groupBy.Currency} {groupBy.Total}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {groupBy.DueDate}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {groupBy.DocumentType}
                      </StyledTableCell>
                      <StyledTableCell color={groupBy.State} align="center">
                        {groupBy.State === 0 || groupBy.State === '0'
                          ? 'Late'
                          : groupBy.State === 1 || groupBy.State === '1'
                          ? 'Pending'
                          : 'Paid'}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          <ContainerHeader>
            <StyledHeadCellMobile
              style={{ color: 'black' }}
              align="center"
            ></StyledHeadCellMobile>
            <StyledHeadCellMobile style={{ color: 'black' }} align="center">
              {t(translations.table.status)}
            </StyledHeadCellMobile>
            <StyledHeadCellMobile style={{ color: 'black' }} align="center">
              {t(translations.table.date)}
            </StyledHeadCellMobile>
            <StyledHeadCellMobile style={{ color: 'black' }} align="center">
              {t(translations.form.ponumber)}
            </StyledHeadCellMobile>
            {/* <StyledHeadCellMobile style={{ color: 'white' }} align="center">
              {t(translations.table.number)}
            </StyledHeadCellMobile> */}
            <StyledHeadCellMobile style={{ color: 'black' }} align="center">
              {t(translations.table.t_invoice)}
            </StyledHeadCellMobile>
            {/* <StyledHeadCellMobile style={{ color: 'white' }} align="center">
              {t(translations.table.doc_type)}
            </StyledHeadCellMobile> */}
          </ContainerHeader>

          {historyInvoices.map(groupBy => (
            <ContainerBody key={Math.random()}>
              <StyledTableCellMobile align="center">
                <img
                  src={drop}
                  alt="drop"
                  style={{ width: '22px', height: '22px' }}
                  onClick={() => openModalWithInfo(groupBy)}
                />
              </StyledTableCellMobile>
              <StyledTableCellMobile color={groupBy.status} align="center">
                {groupBy.State}
              </StyledTableCellMobile>
              <StyledTableCellMobile align="center">
                {groupBy.DocuemntDate}
              </StyledTableCellMobile>
              <StyledTableCellMobile align="center">
                {groupBy.PoNumber}
              </StyledTableCellMobile>
              {/* <StyledTableCellMobile align="center">
                {groupBy.DocumentNumber}
              </StyledTableCellMobile> */}
              <StyledTableCellMobile align="center">
                {groupBy.Currency} {groupBy.Total}
              </StyledTableCellMobile>
              {/* <StyledTableCellMobile align="center">
                {groupBy.DocumentType}
              </StyledTableCellMobile> */}
            </ContainerBody>
          ))}
        </>
      )}
      {modalOpen && info && (
        <ModalComponent
          // page={page}
          closeModal={() => setModalOpen(false)} // Función para cerrar el modal
          data={info} // Información de groupBy para mostrar en el modal
          page="historyI"
        />
      )}
    </ThemeProvider>
  ) : null;
}
