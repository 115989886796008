import Dropdown from 'app/components/Dropdown';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';
import { LoadingPage } from '../LoadingPage';
import { CuppingTable } from 'app/components/CuppingTable';
import { usageMetrics } from 'services/generalServices';
import {
  getListCoffees,
  getListTablesCoffees,
  getSamples,
  getListUsersCompany,
  getSamplesReceived,
} from 'services/cuppingServices';
import { useMediaQuery } from '@mui/material';
import { Hero } from 'app/components/Hero';

const Tab = styled.div<{ state: string }>`
  background-color: ${props =>
    props.state === 'active' ? '#ffffff' : '#4f4e24'};
  color: ${props => (props.state === 'active' ? '#000000' : '#F8F8F8')};
  border-radius: 30px;
  border: 4px solid #4f4e24;
  width: 245px;
  height: 38px;
  display: flex;
  justify-content: center;
  margin: 10px;
  cursor: pointer;
  margin-left: -40px;
  @media (max-width: 768px) {
    border-radius: 0; /* Remove border-radius for mobile */
    margin: 5px; /* Reduce the margin for mobile */
    margin-left: 0; /* Remove negative margin for mobile */
  }
`;

const Text = styled.div`
  font-weight: 325;
  font-size: 20px;
`;

export function CuppingPage() {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingData, setLoadingData] = useState(false);
  const [coffeRaw, setCoffeRaw] = useState<any>([]);
  const [coffeList, setCoffeList] = useState();
  const [errorInfo, setErrorInfo] = useState('');
  const [detail, setDetail] = useState(false);
  const [origins, setOrigins] = useState<any[]>([]);
  const [tables, setTables] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);

  const isMobile = useMediaQuery('(max-width:767px)');

  const getSamplesFunction = () => {
    setErrorInfo('');
    Promise.all([getSamplesReceived(), getSamples()])
      .then(([receivedResponse, samplesResponse]) => {
        if (receivedResponse.status === 200 && samplesResponse.status === 200) {
          const receivedSamples = receivedResponse.data;
          const allSamples = samplesResponse.data;

          allSamples.forEach(sample => {
            if (sample.sample_type == 'Sample Pre Shipment') {
              sample.sample_type = 'Pre-Shipment Sample/PSS';
            }
            if (sample.sample_type == 'Sample Offer') {
              sample.sample_type = 'Offer Sample';
            }
            if (sample.sample_type == 'Sample Arrival') {
              sample.sample_type = 'Arrival Sample';
            }
          });

          const receivedIds = new Set(
            receivedSamples.map(sample => sample.sample_id_atlas),
          );

          const filteredSamples = allSamples.filter(
            sample => !receivedIds.has(sample.sample_id_atlas),
          );

          setCoffeRaw(filteredSamples);
          setLoading(false);
        }
      })
      .catch(error => {
        setErrorInfo(error.response?.data?.message || 'An error occurred');
        setLoading(false);
      });
  };
  const getListCupping = () => {
    Promise.all([getListCoffees(), getSamplesReceived()]) //Function for Table.
      .then(([coffeesResponse, receivedResponse]) => {
        if (coffeesResponse.status === 200 && receivedResponse.status === 200) {
          const allCoffees = coffeesResponse.data;
          const receivedSamples = receivedResponse.data;

          const receivedIds = new Set(
            receivedSamples.map(sample => sample.sample_id),
          );

          const filteredCoffees = allCoffees.filter(
            coffee =>
              !(
                coffee.state === 'initial' && !receivedIds.has(coffee.sample_id)
              ),
          );

          setCoffeList(filteredCoffees);

          const originsSet = new Set(
            filteredCoffees.map(coffee => coffee.origin),
          );
          setOrigins(Array.from(originsSet));

          setLoading(false);
        }
      })
      .catch(error => {
        setErrorInfo(error.response?.data?.message);
        setLoading(false);
      });
    getListUsersCompany()
      .then(response => {
        if (response.status === 200) {
          setUsers(response.data);
          setLoading(false);
        }
      })
      .catch(error => {
        setErrorInfo(error.response?.data?.message);
        setLoading(false);
      });

    getListTables();
  };
  const getListTables = () => {
    getListTablesCoffees() // This is the function for table Table
      .then(response => {
        if (response.status === 200) {
          setTables(response.data);
          setLoading(false);
        }
      })
      .catch(error => {
        setErrorInfo(error.response?.data?.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role === 'Customer') {
      const payload = {
        module: 'Cupping',
        role: 'Customer',
      };
      usageMetrics(payload)
        .then(response => {
          if (response.status === 200) {
            console.log('Metrica enviada');
          }
        })
        .catch(error => {
          console.log(error.response?.data, 'error.response.data');
        });
    }
  }, []);

  useEffect(() => {
    if (page === 1) {
      getSamplesFunction();
      setLoading(true);
    }
    if (page === 2) {
      getListCupping();
      setLoading(true);
    }
    if (page === 3) {
      getListTables();
      setLoading(true);
    }
  }, [page]);

  const cleanFilter = () => {
    setErrorInfo('');
    getSamplesFunction();
    getListCupping();
    getListTables();
  };

  const { t } = useTranslation();

  let hash = {};

  return (
    <>
      <Helmet>
        <title>{t(translations.general.c_session)}</title>
        <meta name="description" content="Cupping Session" />
      </Helmet>
      <div>
        <div
          className="d-flex"
          style={{
            padding: !isMobile ? '0 96px' : '0px',

            flexDirection: 'column',
          }}
        >
          <div className="d-flex justify-content-start w-100">
            <Hero
              title={t(translations.general.cupping)}
              subtitle={t(translations.cupping.description)}
            />
          </div>
          {!detail && (
            <div
              className="d-flex"
              style={{
                justifyContent: 'center',
                marginLeft: !isMobile ? '10px' : '0px',
                flexDirection: !isMobile ? 'row' : 'column',
                alignItems: 'center',
              }}
            >
              <div onClick={() => setPage(1)}>
                <Tab state={page === 1 ? 'active' : 'inactive'}>
                  <Text>{t(translations.general.pending)}</Text>
                </Tab>
              </div>
              <div onClick={() => setPage(2)}>
                <Tab state={page === 2 ? 'active' : 'inactive'}>
                  <Text>{t(translations.general.cupping)}</Text>
                </Tab>
              </div>
              <div onClick={() => setPage(3)}>
                <Tab state={page === 3 ? 'active' : 'inactive'}>
                  <Text>{t(translations.general.table)}</Text>
                </Tab>
              </div>
            </div>
          )}
          {loading ? (
            <LoadingPage />
          ) : (
            <CuppingTable
              page={page}
              setPage={setPage}
              coffeRaw={coffeRaw.filter(item => item.result === null)}
              coffeList={coffeList}
              detail={detail}
              setDetail={setDetail}
              getSamplesFunction={getSamplesFunction}
              getListCupping={getListCupping}
              getListTables={getListTables}
              getListCoffees={getListCoffees}
              errorInfo={errorInfo}
              origins={origins}
              cleanFilter={cleanFilter}
              setCoffeList={setCoffeList}
              setLoading={setLoading}
              tables={tables}
              setTables={setTables}
              users={users}
            ></CuppingTable>
          )}
        </div>
      </div>
    </>
  );
}
