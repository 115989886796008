import CheckListIcon from 'app/assets/SVG/checklist.svg';
import SelectMultiple from 'app/assets/SVG/select_multiple.svg';
import Arrow from 'app/assets/SVG/Arrow.png';
import { LargeButton } from 'app/components/LargeButton';
import { GenericTable } from 'app/components/GenericTable';
import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { GroupedTable } from 'app/components/GroupedTable';
import { useEffect, useState, useRef } from 'react';
import { getHomeCoffees, usageMetrics } from 'services/generalServices';
import { useNavigate } from 'react-router-dom';
import { checkImpersonate, getUser } from 'services/authServices';
import { getHistoryInvoices } from 'services/historyServices';
import { LoadingPage } from '../LoadingPage';
import { getPosition } from 'services/reportServices';
import axios from 'axios';
import { GroupedMobileTable } from 'app/components/GroupedMobileTable';
import { useMediaQuery } from '@mui/material';
import { GroupedTableByWarehouse } from 'app/components/GrupedTableByWarehause';
import { Hero } from 'app/components/Hero';
import { right } from 'inquirer/lib/utils/readline';
import { getSample } from 'services/sampleServices';
import { NavLink } from 'react-router-dom';

const CustomLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  gap: 7%;
  color: #4f4e24;
  text-decoration: none;
  align-items: center;
`;

const Subtitle = styled.div`
  width: 207px;
  height: 36px;
  margin-bottom: 22px;

  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
  @media (max-width: 767px) {
    margin-top: 30px;
    padding-left: 20px;
  }
`;

type SampleType = {
  id: number;
  main_identifier: string;
  ico: string;
  contract: string;
  sample_type: string;
  result: number;
};

export function Home(acc: any) {
  const theme: any = useTheme();
  const [coffes, setCoffes] = useState<any[]>();
  const [userData, setUserData] = useState<any>();
  const [loading, setLoading] = useState<Boolean>(false);
  const [modules, setModules] = useState<any[]>();
  const [permissions, setPermissions] = useState<any[]>();
  const [role, setRole] = useState<any>('');
  const [error, setError] = useState<any>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalInvoices, setIsModalInvoices] = useState(false);
  const [samplesPSS, setSamples] = useState<SampleType[]>([]);

  const isMobile = useMediaQuery('(max-width:767px)');

  const navigate = useNavigate();

  const getPositionCall = () => {
    setError('');
    setLoading(true);
    let url = process.env.REACT_APP_BASE_URL + '/reports/position';
    const token = localStorage.getItem('caravela_access_token');
    axios({
      method: 'GET',
      url,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        if (response.status === 200) {
          setLoading(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Position Report.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        console.log(error.response?.data);
        setError('report_not_found');
        setLoading(false);
      });
  };

  function agruparPorWarehouse(objetos) {
    const grupos = {};

    for (const objeto of objetos) {
      const warehouse = objeto.warehouse;
      if (!grupos[warehouse]) {
        grupos[warehouse] = [];
      }
      grupos[warehouse].push(objeto);
    }

    return grupos;
  }

  const formatCoffe = coffe => {
    let array = [];
    let groupingByWharehouse = Object.values(
      coffe.reduce(
        (acc, current) => {
          if (current.status === 'Afloat') acc.afloats.push(current);
          else if (current.status === 'Contracted')
            acc.contracted.push(current);
          else acc.warehouses.push(current);
          return acc;
        },
        { warehouses: [], afloats: [], contracted: [] },
      ),
    );
    const objetosAgrupados = agruparPorWarehouse(groupingByWharehouse[0]);
    delete groupingByWharehouse[0];
    groupingByWharehouse = groupingByWharehouse.filter(
      item => item !== undefined,
    );
    for (const key in objetosAgrupados) {
      groupingByWharehouse.unshift(objetosAgrupados[key]);
    }
    setCoffes(groupingByWharehouse);
  };

  useEffect(() => {
    const token = localStorage.getItem('caravela_access_token');
    const url = `${process.env.REACT_APP_BASE_URL}/admin-role`;
    if (role) {
      axios
        .get(url, {
          params: { role: role },
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => {
          if (response.status === 200) {
            const filteredModules = response.data.filter(
              value => value.split(';')[1] === 'View',
            );
            const filteredPermissions = response.data.filter(
              value => value.split(';')[1] === 'Customer',
            );
            localStorage.setItem('modules', filteredModules);
            localStorage.setItem('permissions', filteredPermissions);
            setModules(filteredModules);
            setPermissions(filteredPermissions);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [role]);

  const hasMounted = useRef(false);
  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      setLoading(true);

      getHomeCoffees()
        .then(response => {
          if (response.status === 200) {
            formatCoffe(response.data);
          }
        })
        .catch(error => {
          console.log(error.response?.data);
        })
        .finally(() => {
          setLoading(false);
        });

      getUser()
        .then(response => {
          if (response.status === 200) {
            setUserData(response.data);
            setRole(response.data.role);
            localStorage.setItem('role', response.data.role);
            if (response.data.role === 'Customer') {
              try {
                setLoading(true);
                openModalInvoices();
              } catch (error) {
                console.error('Error opening modal invoices:', error);
              }
              try {
                setLoading(true);
                openModalPSSPending();
              } catch (error) {
                console.error('Error opening modal PSS pending:', error);
              }
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
      const role = localStorage.getItem('role');
      if (role === 'Customer') {
        const payload = {
          module: 'Home',
          role: 'Customer',
        };

        usageMetrics(payload)
          .then(response => {
            if (response.status === 200) {
              console.log('Metrica enviada');
            }
          })
          .catch(error => {
            console.log(error.response?.data, 'error.response.data');
          });
      }
    }
  }, []);

  const openModalInvoices = () => {
    const dateInitI = '';
    const dateEndI = '';

    getHistoryInvoices('', '', dateInitI, dateEndI)
      .then(response => {
        if (response.status === 200) {
          if (response.data.data) {
            response.data.data.forEach(element => {
              if (element.State === '0' || element.State === 0) {
                // 0 = late
                setIsModalInvoices(true);
                setLoading(false);
              }
            });
          }
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error.response?.data, 'error.response.data');
      });
  };

  const openModalPSSPending = () => {
    getSample()
      .then(response => {
        if (response.status === 200) {
          const newSamples: SampleType[] = [];

          response.data.forEach(sample => {
            if (
              sample.sample_type === 'Sample Pre Shipment' &&
              sample.result === null
            ) {
              newSamples.push(sample);
              setIsModalOpen(true);
              setLoading(false);
            }
          });
          setSamples(newSamples);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  const closeModal = modal => {
    if (modal === 'samples') {
      setIsModalOpen(false);
    } else if (modal === 'invoices') {
      setIsModalInvoices(false);
    }
  };

  const { t } = useTranslation();

  let contactName = userData ? userData.contact_name : '';
  if (contactName.length > 30) {
    contactName = contactName.substring(0, contactName.indexOf(' '));
  }
  return (
    <>
      {/* Modal */}

      {isModalInvoices && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: '#DFDCCA',
              padding: '30px',
              borderRadius: '10px',
              width: '85%',
              maxWidth: '800px',
              textAlign: 'center',
              position: 'relative',
              fontSize: '25px',
            }}
          >
            <button
              onClick={() => closeModal('invoices')}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                backgroundColor: 'transparent',
                border: 'none',
                fontSize: '30px',
                cursor: 'pointer',
                fontWeight: 'bold',
                color: '#333',
              }}
            >
              ×
            </button>

            <h2>{t(`${translations.pages.history.invoice_title}`)}</h2>
            <p>{t(`${translations.pages.history.notificationInvoice}`)}</p>

            <CustomLink to="/history">
              <button
                style={{
                  backgroundColor: '#4F4E24',
                  padding: '10px 20px',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  marginTop: '20px',
                  marginRight: '10px',
                }}
              >
                {t(`${translations.pages.history.goCheck}`)}
              </button>
            </CustomLink>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div>
          {isModalOpen && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
              }}
            >
              <div
                style={{
                  backgroundColor: '#DFDCCA',
                  padding: '30px',
                  borderRadius: '10px',
                  width: '85%',
                  maxWidth: '800px',
                  textAlign: 'center',
                  position: 'relative',
                  fontSize: '25px',
                }}
              >
                <button
                  onClick={() => closeModal('samples')}
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    fontSize: '30px',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    color: '#333',
                  }}
                >
                  ×
                </button>

                <h2>{t(`${translations.general.notification}`)}</h2>
                <p>
                  {t(`${translations.pages.sample_request.notificationPSS}`)}
                </p>
                <ol>
                  {samplesPSS.map(sample => (
                    <li key={sample.id}>
                      <p>
                        <strong>{t(`${translations.table.sample}`)}:</strong>{' '}
                        {sample.main_identifier} -{' '}
                        <strong>{t(`${translations.table.contract}`)}:</strong>{' '}
                        {sample.contract} - <strong>ICO:</strong> {sample.ico}
                      </p>
                    </li>
                  ))}
                </ol>
                <CustomLink to="/sample-request">
                  <button
                    style={{
                      backgroundColor: '#4F4E24',
                      padding: '10px 20px',
                      color: '#fff',
                      border: 'none',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      marginTop: '20px',
                      marginRight: '10px',
                    }}
                  >
                    {t(`${translations.pages.sample_request.goApprove}`)}
                  </button>
                </CustomLink>
              </div>
            </div>
          )}
        </div>
      )}

      {loading || !role || !permissions ? (
        <LoadingPage />
      ) : (
        <div>
          {/* {isHome === true && ( */}
          {error !== '' && (
            <h3
              style={{
                color: 'red',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              {t(`${translations.errors_400.report_not_found}`)}
            </h3>
          )}
          {/* <Hero size={true} title={t(translations.pages.home.roaster_hub)} /> */}
          <div
            className="d-flex"
            style={{
              padding: '20px 80px 0',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: isMobile ? 'flex-start' : 'right',
              alignItems: 'center',
            }}
          >
            {/* <LargeButton
              color={'#F5515D'}
              number={4}
              text={t(translations.pages.home.pending)}
            /> */}

            {role &&
              permissions &&
              permissions.includes('Downloads;Customer') &&
              !['Admin', 'IT', 'Sales'].some(roles => role.includes(roles)) && (
                <div
                  onClick={() => getPositionCall()}
                  style={{
                    marginRight: isMobile ? '0px' : '40px',
                    marginBottom: isMobile ? '20px' : '0px',
                    alignContent: 'right',
                    cursor: 'pointer',
                  }}
                >
                  {/* Download position report button*/}
                  {/* <LargeButton
                    color={theme.palette.primary.main}
                    icon={Arrow}
                    text={t(translations.pages.home.position)}
                    secondaryText={t(translations.pages.home.download_pdf)}
                  /> */}
                </div>
              )}
            {modules && modules.includes('Sample Request;View') && (
              <div
                onClick={() => navigate(`/sample-request`)}
                style={{
                  cursor: 'pointer',
                  height: isMobile ? '80px' : '100%',
                }}
              >
                {/*Button redirect sample request*/}
                {/* <LargeButton
                  textColor={theme.palette.secondary.contrastText}
                  color={theme.palette.secondary.main}
                  icon={CheckListIcon}
                  text={t(translations.general.sample_preshipment)}
                /> */}
              </div>
            )}
          </div>
          {/* )} */}
          <div
            className="d-flex "
            style={{
              padding: !isMobile ? '10px 96px ' : '0 15px',
              flexDirection: 'column',
            }}
          >
            {/* {isHome === true && ( */}
            <>
              {coffes ? (
                <Hero title={t(translations.general.available)} />
              ) : (
                <>
                  <h3
                    style={{
                      color: 'gray',
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    {t(`${translations.errors_400.no_data_available}`)}
                  </h3>
                  <h6
                    style={{
                      color: 'gray',
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    {t(`${translations.errors_400.description_error}`)}
                  </h6>
                </>
              )}

              {!isMobile ? (
                <>
                  {/* <GroupedTable coffes={coffes} page={'home'} /> */}
                  <GroupedTableByWarehouse
                    coffes={coffes}
                    page={'home'}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </>
              ) : (
                <GroupedMobileTable
                  coffes={coffes}
                  page={'home'}
                  setError={setError}
                />
              )}
            </>
            {/* )} */}
          </div>
        </div>
      )}
    </>
  );
}
